import {
  TeaserLarge as TeaserLargeComponent,
  TTeaserData as TTeaserDataComponent,
} from 'components/TeaserLarge';

import type { Teaser as TTeaser } from 'server/repository/hygraph/generated/graphqlTypes';
import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';

interface ITeaserLarge extends ISharedTemplateProps {
  templateProps: TTeaser & {
    teasers: TTeaserDataComponent[][];
  };
}

export const TeaserLarge = ({ templateProps: { teasers }, theme }: ITeaserLarge) => {
  return <TeaserLargeComponent teasers={teasers} theme={theme} />;
};
