import { Grid, GridRow } from 'components/Grid';
import { LiquidButton } from 'components/LiquidButton';
import NextImage from 'components/NextImage';
import { useEffect, useState } from 'react';
import { ImagesService } from 'service/images/imagesService';

import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import { LinksService } from 'service/links/linksService';
import { COLORS } from 'styles/constants';
import { ISharedTemplateProps } from 'templates/TemplateMapper/types';
import {
  Content,
  Image,
  StyledButton,
  StyledHeading,
  StyledText,
  StyledTitle,
  Teaser,
} from './styles';
import { Slider } from '../SliderVanilla';

export type TTeaserData = {
  id?: string;
  link?: {
    url: string;
    displayName: string;
  };
  title?: string;
  heading?: string;
  description?: string;
  images: {
    desktop?: string;
    desktopFallback?: string;
    mobile?: string;
    mobileFallback?: string;
  };
  startDate?: string;
  endDate?: string;
  type?: string;
  isAlgolia?: boolean;
};

interface ITeaserLarge {
  teasers: TTeaserData[][];
}

export const TeaserLarge = ({ teasers, theme }: ITeaserLarge & ISharedTemplateProps) => {
  const { pathTranslations } = useGlobalContext();
  const [filteredTeasers, setFilteredTeasers] = useState<TTeaserData[][]>();
  const [showSlider, setShowSlider] = useState<boolean>(false);
  useEffect(() => {
    const currentDate = new Date();
    const newTeasers = teasers?.map((teaserContainer) => {
      let algoliaTeasers = [];
      const nonAlgoliaTeasers = teaserContainer.filter(
        (item) =>
          new Date(item.startDate || '') < currentDate &&
          new Date(item.endDate || '') > currentDate &&
          !item.isAlgolia,
      );
      algoliaTeasers = teaserContainer.filter(
        (item) =>
          new Date(item.startDate || '') < currentDate &&
          new Date(item.endDate || '') > currentDate &&
          item.isAlgolia,
      );
      const randomAlgoliaTeaser = algoliaTeasers[Math.floor(Math.random() * algoliaTeasers.length)];
      return [...nonAlgoliaTeasers, randomAlgoliaTeaser].filter((x) => x !== undefined);
    });
    setFilteredTeasers(newTeasers);
  }, []);
  useEffect(() => {
    setShowSlider(true);
  }, [filteredTeasers]);
  return (
    <Grid>
      <GridRow className="mt-10">
        {showSlider ? (
          <Slider slidesPerView={1} isFullWidth isStaticArrows>
            {filteredTeasers?.map((teaserContainer: TTeaserData[]) => (
              <>
                {teaserContainer?.map((teaser: TTeaserData, index) => (
                  <Teaser key={`teaser-${teaser.id ?? index}`}>
                    <Content>
                      {theme && <StyledHeading>{teaser.title}</StyledHeading>}
                      <StyledTitle type="md" tag="h4">
                        {theme && teaser.heading ? teaser.heading : teaser.title}
                      </StyledTitle>
                      <StyledText type="md" tag="div">
                        {teaser.description}
                      </StyledText>
                      <StyledButton
                        href={
                          LinksService.getInternalLinks({
                            item: teaser?.link,
                            pathTranslations,
                          }) ||
                          teaser?.link?.url ||
                          ''
                        }
                        className={theme && 'flex justify-center md:justify-start'}
                      >
                        <LiquidButton
                          text={teaser?.link?.displayName || 'Teaser'}
                          height={50}
                          width={250}
                          color={COLORS.primary}
                          textColor={COLORS.white}
                        />
                      </StyledButton>
                    </Content>
                    <Image $type={teaser.type}>
                      <NextImage
                        sources={ImagesService.getPictureSources(teaser.images)}
                        alt={teaser.title ?? ''}
                      />
                    </Image>
                  </Teaser>
                ))}
              </>
            ))}
          </Slider>
        ) : null}
      </GridRow>
    </Grid>
  );
};
